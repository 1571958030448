import React, { useEffect, useState } from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import StringUtil from '../lib/StringUtil';
import ArrayUtil from '../lib/ArrayUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      padding: '1em',
      color: 'ghostwhite',
      backgroundColor: 'lightslategray',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.typography.fontFamily
    },
    answer: {
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: theme.typography.fontFamily
    },
    heading: {
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: theme.typography.fontFamily
    },
    expansionPanelSummaryContent: {
      "& > :last-child": {
        padding: 0
      }
    },
    expansionPanelSummaryExpandIcon: {
      top: "80%",
      color: "lightgrey"
    },
    expansionPanelSummaryExpandIconIncorrect: {
      top: "80%",
      color: "red",
      transform: 'none !important',
    },
    expansionPanelSummaryExpandIconCorrect: {
      top: "80%",
      color: "green",
      transform: 'none !important',
    },
    incorrect: {
      color: "red"
    },
    correct: {
      color: "green"
    },
    small: {
      fontSize: theme.typography.pxToRem(14)
    }
  }),
);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export interface IChoice {
  title: string
  explanations: string[]
  correctness: number
  choiceIndex: number
  selectedIndex?: number
}

export interface IAnswer {
  index: number
  correctness: number
}

const getCorrectnessText = (correctness: number, score: number | undefined, totalScore: number) => {
  // Incorrect
  if (correctness === 0) {
    return 'Try again.'
  }

  // Correct
  if (correctness === 1 && totalScore === 1) {
    return 'Correct answer.'
  }

  // Partial Correct
  return `${score} of ${totalScore} correct answers.`
}

const Choices = ({ choices, onAnswer, onDone, classes }: { choices: IChoice[], onAnswer: Function, onDone: Function, classes: any }) => {
  const [score, setScore] = useState<number>(0)
  const [expanded, setExpanded] = useState<number | false>(false);
  const [remain, setRemain] = useState<number>(choices.length)

  const totalScore = choices.filter(e => e.correctness > 0).length

  const handleChange = (choice: IChoice, panelIndex: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (typeof (choice.selectedIndex) === "undefined") return
    if (choice.selectedIndex > -1) return

    setScore(score => {
      const selectedIndex = score + choice.correctness
      choice.selectedIndex = selectedIndex

      // Track
      onAnswer && onAnswer(
        choice.choiceIndex,
        choice.correctness
      )

      // Done with all correct
      if (selectedIndex === totalScore) onDone(choices)

      return selectedIndex
    });

    setExpanded(isExpanded ? panelIndex : false);
  };

  useEffect(() => {
    setRemain(remain => --remain)
  }, [score])

  useEffect(() => {
    // Done when all open
    if (remain === 0) onDone(choices)
  }, [remain, onDone, choices])

  const getExplanations = (explanations: string[]) => (!explanations || explanations.length <= 0) ? <></>
    : <>{StringUtil.parseMDAndHTMLs(explanations)}<br /><br /></>

  return <>{choices.map((choice, i) => {
    const isExpand = (choice.selectedIndex !== -1) || (expanded === i)

    return (<Accordion key={`accordion${i}`} expanded={isExpand} onChange={handleChange(choice, i)}>
      <AccordionSummary
        classes={{
          content: classes.expansionPanelSummaryContent,
          expandIcon: isExpand ? (choice.correctness === 0 ? classes.expansionPanelSummaryExpandIconIncorrect : classes.expansionPanelSummaryExpandIconCorrect) : classes.expansionPanelSummaryExpandIcon
        }}
        expandIcon={isExpand ? (choice.correctness === 0 ? <ClearIcon /> : <CheckIcon />) : <ExpandMoreIcon />}
      >
        <Grid className={classes.heading + ' ' + (isExpand ? (choice.correctness === 0 ? classes.incorrect : classes.correct) : '')}>{StringUtil.parseMDAndHTML(choice.title)}</Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className={classes.answer}>
          {getExplanations(choice.explanations)}
          <span className={classes.small + ' ' + (choice.correctness === 0 ? classes.incorrect : classes.correct)}>{
            getCorrectnessText(choice.correctness, choice.selectedIndex, totalScore)
          }</span>
        </Grid>
      </AccordionDetails>
    </Accordion>)
  })}</>
}

export const prepData = (choices: IChoice[], isRandom: boolean = true) => {
  // Random
  isRandom && ArrayUtil.random(choices)

  // Add index
  choices.forEach((e: IChoice, index: number) => {
    e.choiceIndex = index
    e.selectedIndex = -1
  })

  return choices
}

const MultipleChoices = ({ questions, choices, onAnswer, onDone }: { questions: string[], choices: IChoice[], onAnswer: Function, onDone: Function }) => {
  const classes = useStyles();

  return <><Box borderRadius="8px" boxShadow={1}>
    <Grid className={classes.question}>
      {StringUtil.parseMDAndHTMLs(questions)}
    </Grid>
    <Choices choices={choices} onAnswer={onAnswer} onDone={onDone} classes={classes} />
  </Box></>
}

export default MultipleChoices