import React, { useEffect, useState } from 'react';

import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import MultipleChoices, { prepData, IChoice } from './components/MultipleChoices'

import CommitRating, { SaveStatus, SaveStatusType } from './components/CommitRating';

import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string'

import GA4React from 'ga-4-react'
import { GA4ReactResolveInterface } from 'ga-4-react/dist/lib/gtagModels';

import Cookies from 'js-cookie'
import { isLocal } from './lib/DeveloperUtil';
import { Typography } from '@material-ui/core';
import StringUtil from './lib/StringUtil'

import { useMeasure } from "react-use";
import { exposeHeight } from './lib/SizeUtil';
import { ack } from './lib/ack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    section1: {
      margin: theme.spacing(1, 1),
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    }
  }),
);

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 6,
    },
    colorPrimary: {
      backgroundColor: 'bisque',
    },
    bar: {
      borderRadius: 6,
      backgroundColor: 'coral',
    },
  }),
)(LinearProgress);

interface IChoiceData {
  sku: string
  questionId: string
  questionName: string
  questions: string[]
  choices: IChoice[]
}

const loadGist = async (gist: string) => {
  const response = await fetch(gist)
  const json = await response.json()

  // Validate version
  if (json.template_version !== '1.0.0') {
    console.error(`Unsupported version:${json.template_version}`)
  }

  // Get id
  // THIS NOT WORKING IN SAFARI https://caniuse.com/js-regexp-lookbehind
  // const questionIds = gist.match(/(?<=\/)(\w{32})(?=\/)/)
  const questionIds = gist.split('/')
  if (!questionIds || !questionIds[4]) {
    throw new Error(`Not found gist id`)
  }
  const questionId = questionIds[4]

  // Get name
  // THIS NOT WORKING IN SAFARI https://caniuse.com/js-regexp-lookbehind
  // const questionNames = gist.match(/[^/]*(?=\.\w+$)/)
  const questionNames = gist.split('/')
  if (!questionNames || !questionNames[6]) {
    throw new Error(`Not found gist name`)
  }
  const questionName = questionNames[6].split('.json')[0]

  // Get sku
  const sku = questionName.split('_')[0]

  prepData(json.choices)

  return { sku, questionId, questionName, ...json }
}

/**
 * @param gid 
 */
const initGA4 = async (gid: string) => {
  if (!gid || !gid.startsWith('G')) {
    console.warn(`Required G-... :`, gid)
    return null
  }

  const ga4react = new GA4React(gid)
  const ga4 = await ga4react.initialize().catch(console.error)

  if (!ga4) {
    console.warn(`GA failed to init :`, gid)
    return null
  }

  return ga4
}

let ga4: GA4ReactResolveInterface | null
let isAnswerStarted = false

// uid
const uid = isLocal ? 'developer' : (Cookies.get('uid') || 'guest')

const EXAMPLE_LINK = `https://quiz.foxfox.io/?gid=G-DMXNNQ4CJH&gist=https://gist.githubusercontent.com/katopz/f02c0eb67cd82c9eeed033a9c6f99a68/raw/foxfox-quiz-example.json#{"height":300}`
const EXAMPLE_TEXT = `How to use : <a href="${EXAMPLE_LINK}">${EXAMPLE_LINK}</a>`

const App = () => {
  const [divRef, { height }] = useMeasure();

  const classes = useStyles()

  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<IChoiceData>()
  const [isDone, setIsDone] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>()

  const [saveStatus, setSaveStatus] = useState<SaveStatusType>(SaveStatus.not_save);

  const trackStart = () => {
    const event_category = `answer_start`
    const params = {
      uid,
      question_id: data?.questionId,
      question_name: data?.questionName,
    }

    ga4?.gtag('event', event_category, params)
  }

  const trackEnd = (correctness: number) => {
    const event_category = `answer_end`
    const params = {
      uid,
      question_id: data?.questionId,
      question_name: data?.questionName,
      correctness
    }

    ga4?.gtag('event', event_category, params)
  }

  const trackAnswer = (choiceIndex: number, correctness: number) => {
    // const event_category = `answer_${correctness ? 'correct' : 'incorrect'}`
    // const event_category = `answer_${choiceIndex}`
    const event_category = `answer`
    const params = {
      uid,
      question_id: data?.questionId,
      question_name: data?.questionName,
      answer_id: `${data?.questionId}:${choiceIndex}`,
      correctness
    }

    ga4?.gtag('event', event_category, params)
  }

  const trackRating = async (rating: number) => {
    const event_category = `rate`
    const params = {
      uid,
      question_id: data?.questionId,
      question_name: data?.questionName,
      rating
    }

    ga4?.gtag('event', event_category, params)

    // Acknowledge
    if (!data) return
    setSaveStatus(SaveStatus.saving)

    setTimeout(async () => {
      const href = window.location.href
      const { sku, questionId } = data
      const json = await ack(sku, questionId, {
        href,
        event_category,
        params,
      })

      const _saveStatus = json.success === false ? SaveStatus.save_failed : SaveStatus.save_succeed
      setSaveStatus(_saveStatus)
    }, 0)
  }

  const onAnswer = (choiceIndex: number, correctness: number) => {
    if (!isAnswerStarted) {
      isAnswerStarted = true
      trackStart()
    }

    trackAnswer(choiceIndex, correctness)

    exposeHeight()
  }

  const onDone = (correctness: number) => {
    if (isDone) return

    trackEnd(correctness)
    setIsDone(true)
  }

  useEffect(() => {
    if (data) return

    const { gist, gid } = queryString.parse(location.search)

    // Analytics
    initGA4(gid as string).then(_ga4 => ga4 = _ga4)

    // Content
    if (!gist) {
      setErrorText(EXAMPLE_TEXT)
      return
    }

    loadGist(gist as string).then(_data => {
      setData(_data)
    })

  }, [data, history, location]);

  useEffect(() => {
    exposeHeight()
  }, [height, data]);

  // Guard error
  if (errorText) {
    return <div className={classes.root}>
      <div className={classes.section1}>
        <Typography>{StringUtil.parseMDAndHTML(errorText)}</Typography>
      </div>
    </div>
  }

  if (!data) {
    return <div className={classes.root}>
      <div className={classes.section1}>
        <BorderLinearProgress />
      </div>
    </div>
  }

  const { questions, choices, sku, questionId } = data

  if (!questions || !choices) {
    throw new Error(`Missing data`)
  }

  if (!questionId || !sku) {
    throw new Error(`Missing questionId, sku`)
  }

  return (
    // @ts-ignore
    <div ref={divRef}>
      <div className={classes.root}>
        <div className={classes.section1}>
          <MultipleChoices questions={(questions)} choices={choices} onAnswer={onAnswer} onDone={onDone} />
          <CommitRating visible={isDone} onDone={trackRating} saveStatus={saveStatus} />
        </div>
      </div>
    </div >
  );
}

export default App;
