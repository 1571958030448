export const ack = async (sku: string, questionId: string, params: any) => {
  // Auto ack from content.foxfox.io
  // if (!['content.foxfox.io', 'localhost'].includes(window.location.hostname)) return;

  // console.log('ack')
  // await fetch(`https://api.foxfox.io/v1/ack/quiz/${sku}/${questionId}`, {
  //   credentials: 'include',
  // })

  const res = await fetch(`https://api.foxfox.io/v1/ack/quiz/${sku}/${questionId}`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(params)
  })

  const json = await res.json()

  return json
}