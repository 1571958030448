import ReactHtmlParser from 'react-html-parser'
import snarkdown from 'snarkdown'

const parseMDAndHTML = (str: string) => ReactHtmlParser(snarkdown(str))
const parseMDAndHTMLs = (strArray: string[]) => {
  if (!strArray) return ''
  return strArray.map((e, i) => parseMDAndHTML((i > 0 ? '<br/>' : '') + e))
}

const StringUtil = { parseMDAndHTML, parseMDAndHTMLs }
export default StringUtil
