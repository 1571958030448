import React, { ChangeEvent, useEffect, useState } from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    control: {
      padding: theme.spacing(2),
    },
    container: {
      padding: '1em',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      fontWeight: theme.typography.fontWeightMedium
    },
    hint: {
      marginTop: '0.3em'
    },
    rating: {
      marginTop: '0.2em',
    },
    save: {
      backgroundColor: 'coral'
    }
  }),
);

export enum SaveStatus {
  not_save = 'not_save',
  saving = 'saving',
  save_succeed = 'save_succeed',
  save_failed = 'save_failed',
}

export type SaveStatusType = SaveStatus.not_save | SaveStatus.saving | SaveStatus.save_succeed | SaveStatus.save_failed;

const RateBar = (props: any) => {
  const { saveStatus, classes, value, onChange } = props
  switch (saveStatus) {
    default:
    case SaveStatus.not_save:

      return <Grid container justify="center" spacing={4}>
        <Grid key={0} item>
          <Typography className={classes.hint}>Please give a rating</Typography>
        </Grid>
        <Grid key={1} item>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={onChange}
            className={classes.rating}
            disabled={value !== -1}
          />
        </Grid>
        <Grid key={2} item>
          <Typography className={classes.hint}>to save your score.</Typography>
        </Grid>
      </Grid>
    case SaveStatus.saving: return <Grid container justify="center">
      <Typography className={classes.hint}>🌀 Saving...</Typography>
    </Grid>
    case SaveStatus.save_failed: return <Grid container justify="center">
      <Typography className={classes.hint}>Save failed... Try again later 🤔</Typography>
    </Grid>
    case SaveStatus.save_succeed:
      const emoticon = value < 4 ? '( ಠ ʖ̯ ಠ ) ' : '(づ￣ ³￣)づ '
      return <Grid container justify="center">
        <Grid key={0} item>
          <Typography className={classes.hint}>{emoticon}</Typography>
        </Grid>
        <Grid key={1} item>
          <Rating
            name="simple-controlled"
            value={value}
            className={classes.rating}
          />
        </Grid>
        <Grid key={2} item>
          <Typography className={classes.hint}> ヽ(ˇ∀ˇ )ゞ Saved! 🙌</Typography>
        </Grid>
      </Grid>
  }
}

const CommitRating = ({ rating = -1, onDone, visible = false, saveStatus }:
  { rating?: number, onDone: Function, visible?: boolean, saveStatus: SaveStatus }) => {
  const [value, setValue] = useState<number | null>(rating);

  useEffect(() => {
    if (value === -1) return
    if (saveStatus !== SaveStatus.not_save) return

    onDone && onDone(value)
  }, [onDone, value, saveStatus])

  const onChange = (event: ChangeEvent<{}>, newValue: number | null) => {
    if (value !== -1) return
    if (saveStatus !== SaveStatus.not_save) return

    setValue(newValue)
  }

  const classes = useStyles()

  return (
    <Box
      marginTop='1em'
      padding='0.6em'
      borderRadius='8px'
      color='ghostwhite'
      bgcolor='lightslategray'
      boxShadow={1}
      display={visible ? 'block' : 'none'}
    >
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <RateBar saveStatus={saveStatus} classes={classes} value={value} onChange={onChange} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CommitRating
