import TWEEN from '@tweenjs/tween.js';
const setHeight = (height: string) => {
  const payload = {
    source: window.location.href,
    height
  }

  window.top.postMessage(payload, '*')//, window.location.href)
}

const getHeight = () => {
  const root: any = document.getElementById('root')
  // const computedStyle = window.getComputedStyle(root)
  // const _height = computedStyle.getPropertyValue('height')
  // console.log('root.offsetHeight:', root.offsetHeight)
  // const height = (20 + parseFloat(_height.split('px')[0])) + 'px'
  const height = (18 + root.offsetHeight) + 'px';

  return height
}

const tweenObject = {}
let tween: any
let prevHeight: any

export const exposeHeight = () => {
  if (tween) tween.stop()

  const doSetHeight = () => {
    const height = getHeight()
    if (prevHeight === height) return;
    prevHeight = height
    // console.log('*exposeHeight:', height);
    setHeight(height);
  }

  tween = new TWEEN.Tween(tweenObject)
    .to({}, 1000)
    .onUpdate(() => {
      doSetHeight();
    })
    .onComplete(() => {
      doSetHeight();
    })
    .start()
}

function animate() {
  requestAnimationFrame(animate)
  TWEEN.update()
}

animate()